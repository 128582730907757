import Question from './Question';
import About from './About';
import Faq from './Faq';
import Team from './Team'
import Footer from './Footer';

function Home() {
  return (
    <>
      <div id="home" className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4 md:gap-5">
        <section className='absolute w-[50px] h-[50px] bg-[rgba(99,185,145,0.35)] rounded-full top-[200px] right-[100px]'></section>
        <section className='absolute w-[80px] h-[30px] bg-[rgba(50,50,214,0.2)] rounded-full bottom-[200px] left-[10px]'></section>
        <section className='absolute w-[80px] h-[30px] bg-[rgba(50,50,214,0.2)] rounded-full bottom-[200px] right-[10px]'></section>
        <h1 className="text-4xl font-bold text-green-600 mb-4 md:text-5xl">
          Empower your investments with precision and insight
        </h1>
        <p className="text-lg text-gray-600 mb-8 md:text-3xl md:text-center">
          Accurate and insightful, <span className='font-bold text-green-950'>AI-powered</span> stock market predcitions
        </p>
        <h2 className="text-3xl font-bold text-green-900 mb-4">
          Driven by ideas influenced by trends
        </h2>
        <div className="flex flex-row gap-10 items-end md:space-x-4 space-y-4 md:space-y-0">
          <a
            href="/tool"
            className="bg-green-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition duration-300 no-underline"
          >
            Try for Free
          </a>
          <a
            href="#faq"
            className="bg-gray-200 text-blue-600 px-6 py-3 rounded-lg shadow-md hover:bg-gray-300 transition duration-300 no-underline"
          >
            Learn More
          </a>
        </div>
      </div>
      <Question />
      <About />
      <Faq />
      <Team />
      <Footer />
    </>
  );
}

export default Home;