import './App.css'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import Header from './components/Header'
import Home from './components/Home';
import Tool from './components/Tool';
import { Routes, Route, Navigate } from 'react-router-dom'
import Contact from './components/Contact';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/tool' element={<Tool />} />
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
      <Analytics />
      <SpeedInsights />
    </>
  );
}

export default App;