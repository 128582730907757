import { useState } from "react"

const Dropdown = ({ question, answer }) => {

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="flex flex-col justify-center m-5 md:pb-2 md:-my-2 lg:flex lg:flex-col lg:w-[70vw]">
            <div
                className="bg-white shadow-md rounded-lg p-4 cursor-pointer flex justify-between items-center"
                onClick={toggleDropdown}
            >
                <h3 className="font-semibold text-[16px] md:text-[24px]">{question}</h3>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
            </div>
            <div
                className={`overflow-hidden transition-all duration-700 ease-in-out ${isOpen ? 'max-h-40' : 'max-h-0'
                    }`}
            >
                <div className="bg-white p-4 rounded-lg mt-2 md:text-[22px]">
                    <p>{answer}</p>
                </div>
            </div>
        </div>
    )
}

export default Dropdown;