function About() {
  return (
    <div id="about" className="container-fluid flex-col p-5 min-h-screen md:flex md:items-center bg-gray-100 lg:flex lg:flex-col xl:flex xl:flex-col xl:items-center">
      <h2 className="font-bold text-3xl md:text-[42px] my-[50px] mx-3 text-green-700">About Us</h2>
      <div className="md:w-auto md:text-2xl md:flex md:flex-col md:gap-10 lg:flex lg:flex-row lg:gap-0 lg:flex-wrap xl:flex xl:flex-row xl:w-auto xl:justify-center leading-6 md:leading-9">
        <section className="about-us">
          <p>We're tech enthusiasts, transforming stock trading with accurate predictions. Our mission is to empower both beginners and experienced traders to achieve their financial goals confidently. Join us to unlock stock market success.</p>
        </section>
        <section className="about-us">
          <p>We combine historical data, statistical models, and machine learning to predict stock prices. Our process includes data collection, model selection, training, validation, and predcition to deliver actionable insights.</p>
        </section>
        <section className="about-us">
          <p>Insight you gain: Predict prices, analyse trends, optimize portfolios, and identify opportunities with our tool.</p>
        </section>
      </div>
    </div>
  )
}

export default About;