function Modal({ isOpen, onClose, modalTitle, modalBody }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className={`bg-white rounded-lg shadow-lg p-6 max-w-sm w-full ${typeof(modalBody) == "string" ? 'h-[100px]' : 'h-[500px]'} overflow-y-scroll m-4`}>
        <h2 className="text-xl font-bold mb-4">{modalTitle}</h2>
        {
          typeof (modalBody) !== "string" ?
            <ul>
              {
                modalBody.map(list => <li className="m-3 text-[14px] md:text-[16px]">{list}</li>)
              } 
            </ul> :
            <p>{modalBody}</p>
        }
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded mr-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;