import PredAnalytics from '../images/adv_pre_analytics.jpeg'
import Interface from '../images/interface.jpeg'

function Question() {
    return (
        <div id='why-markecast' className="container-fluid flex bg-[rgba(183,211,240,0.31)] flex-col md:gap-10 min-h-screen lg:items-center">
            <h2 className="font-bold text-3xl md:text-[42px] mt-20 mb-10 mx-3 text-green-700">Why Markecast?</h2>
            <section className="advanced-pred flex flex-col items-center gap-10 md:p-10 lg:w-[60vw] lg:flex-row-reverse">
                <p className="text-[18px]  mx-3 md:text-[28px]"><strong className="font-bold text-green-950">Advanced Predictive Analysis</strong> - Leverage our <span className="span">AI-driven</span> models to forecast stock prices with precision. Our tools analyse vast historical data and current market trends to deliver reliable predictions.</p>
                <img src={PredAnalytics} alt='Predictive analytics' className="w-[150px] md:w-[200px] rounded-[50%] mix-blend-multiply shadow-[1px_1px_10px_blue]" />
            </section>
            <section className='user-interface flex flex-col items-center gap-10 mt-10 md:p-10 md:-mt-10 lg:w-[60vw] lg:mt-10 lg:flex-row lg:items-center mb-10'>
                <p className="text-[18px] my-10 mx-3 md:text-[28px]"><strong className="font-bold text-green-950">User Friendly Interface </strong>- Experience a seamless and intuitive user interface. Our platform is designed to be accessible and easy to navigate, providing you with a hassle-free user experience.</p>
                <img src={Interface} alt='User-Interface' className="w-[150px] md:w-[200px] rounded-[50%] mix-blend-multiply shadow-[1px_1px_10px_blue]" />
            </section>
        </div>
    )
}

export default Question;