import '../App.css';
import { useState } from 'react'
import LoadingSpinner from './Spinner';
import Modal from './Modal';

function Tool() {

  const [value, setValue] = useState([])
  const [forecast, setForecast] = useState([])
  const [selectedValue, setSelectedValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false)
  const [forecastLoading, setForecastLoading] = useState(false)

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value)
    setValue([])
    setForecast([])
  }

  async function getStockData() {
    let token = await generateToken();
    if (token) {
      await getData(token);
    } else {
      console.error('Authentication failed');
    }
  }

  async function getForecastData() {
    let token = await generateToken();
    if (token) {
      await getRevenueData(token);
    } else {
      console.error('Authentication failed');
    }
  }

  async function generateToken() {
    try {
      const response = await fetch(`https://markecastbackend-ai7xm72o.b4a.run/generate-token`, {
        method: 'POST',
      });
      // const response = await fetch('https://markecast-backend.onrender.com/generate-token', {
      //   method: 'POST'
      // })
      if (!response.ok) {
        throw new Error('Failed to generate token');
      }
      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error('There was a problem generating token:', error);
      return null;
    }
  }

  const getData = async (token) => {
    if (selectedValue === "") {
      setIsModalOpen(true)
    } else
      setDataLoading(true)
    // fetch(`https://markecast-backend.onrender.com/?stock=${encodeURIComponent(selectedValue)}`, {
    //   method: 'GET',
    //   headers: {
    //     'Authorization': `Bearer ${token}`
    //   }
    // })
    fetch(`https://markecastbackend-ai7xm72o.b4a.run/api/data/?stock=${encodeURIComponent(selectedValue)}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(data => {
        const keys = data[0];

        // Initialize an empty array to store objects
        const result = [];

        // Iterate over the remaining arrays and create objects
        for (let i = 1; i < data.length; i++) {
          const obj = {};
          for (let j = 0; j < keys.length; j++) {
            obj[keys[j]] = data[i][j];
          }
          result.push(obj);
        }
        setDataLoading(false)
        setValue(result)
      })
      .catch(err => console.log(err))
  }

  const getRevenueData = async (token) => {
    if (selectedValue === "") {
      setIsModalOpen(true)
    } else {
      setForecastLoading(true)
      // fetch(`https://markecast-backend.onrender.com/api/data/?stock=${encodeURIComponent(selectedValue)}`, {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': `Bearer ${token}`
      //   }
      // })
      fetch(`https://markecastbackend-ai7xm72o.b4a.run/api/forecastdata/?stock=${encodeURIComponent(selectedValue)}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(data => {
          let transformed_data = data.stock_price.map((value, index) => ({
            month: data.month[index],
            stock_price: value,
            revenue: data.revenue[index],
            profit: data.profit[index],
            volume: data.volume[index],
            vwap: data.vwap[index],
            vc: data.vc[index]
          }))
          setForecastLoading(false)
          setForecast(transformed_data)
        })
        .catch(err => console.error(err))
    }
  }
  return (
    <div className='container-fluid mt-24 flex flex-col items-start gap-10 m-4 text-[8px] overflow-x-auto md:text-[12px] md:flex md:items-center lg:text-[16px]'>
      <div className="mb-3 stock-select">
        <label htmlFor="" className="font-semibold text-xl">Stocks</label>
        <div className='flex gap-20 mt-5 items-center'>
          <select className="p-2 bg-purple-950 rounded-lg text-white font-semibold text-[8px] md:text-[12px] lg:text-[16px]" name="" id="" onChange={handleSelectChange}>
            <option value="">Please select one...</option>
            <option value="ADANIENT">ADANIENT</option>
            <option value="ADANIPORTS">ADANIPORTS</option>
            <option value="APOLLOHOSP">APOLLOHOSP</option>
            <option value="ASIANPAINTS">ASIANPAINTS</option>
            <option value="AXISBANK">AXISBANK</option>
            <option value="BAJAJAUTO">BAJAJAUTO</option>
            <option value="BAJAJFINANCE">BAJAJFINANCE</option>
            <option value="BAJAJFINSERV">BAJAJFINSERV</option>
            <option value="BEL">BEL</option>
            <option value="BHARTIAIRTEL">BHARTIAIRTEL</option>
            <option value="BPCL">BPCL</option>
            <option value="BRITANNIA">BRITANNIA</option>
            <option value="CIPLA">CIPLA</option>
            <option value="COALINDIA">COALINDIA</option>
            <option value="DRREDDY">DRREDDY</option>
            <option value="EICHERMOTORS">EICHERMOTORS</option>
            <option value="GRASIM">GRASIM</option>
            <option value="HCLTECH">HCLTECH</option>
            <option value="HDFCBANK">HDFCBANK</option>
            <option value="HDFCLIFE">HDFCLIFE</option>
            <option value="HEROMOTOCORP">HEROMOTOCORP</option>
            <option value="HINDALCO">HINDALCO</option>
            <option value="HINDUNILVR">HINDUNILVR</option>
            <option value="ICICIBANK">ICICIBANK</option>
            <option value="INDUSINDBK">INDUSINDBK</option>
            <option value="INFY">INFY</option>
            <option value="ITC">ITC</option>
            <option value="JSWSTEEL">JSWSTEEL</option>
            <option value="KOTAKBANK">KOTAKBANK</option>
            <option value="LT">LT</option>
            <option value="M&M">M&M</option>
            <option value="MARUTI">MARUTI</option>
            <option value="NESTLE">NESTLE</option>
            <option value="NTPC">NTPC</option>
            <option value="ONGC">ONGC</option>
            <option value="POWERGRID">POWERGRID</option>
            <option value="RELIANCE">RELIANCE</option>
            <option value="SBI">SBI</option>
            <option value="SBILIFE">SBILIFE</option>
            <option value="SHRIRAMFIN">SHRIRAMFIN</option>
            <option value="SUNPHARMA">SUNPHARMA</option>
            <option value="TATACONS">TATACONS</option>
            <option value="TATAMOTORS">TATAMOTORS</option>
            <option value="TATASTEEL">TATASTEEL</option>
            <option value="TCS">TCS</option>
            <option value="TECHM">TECHM</option>
            <option value="TITAN">TITAN</option>
            <option value="TRENT">TRENT</option>
            <option value="ULTRATECH">ULTRATECH</option>
            <option value="WIPRO">WIPRO</option>
          </select>
          {selectedValue === "" ? "" : <p className="bg-emerald-800 p-2 rounded-lg text-white font-semibold">Stock - {selectedValue}</p>}
        </div>
      </div>
      {
        isModalOpen && (
          <Modal isOpen={openModal} onClose={closeModal} modalTitle="Warning" modalBody="Please select a stock from the list" />
        )
      }
      <div className='flex flex-col gap-10 items-center'>
        <table className='table'>
          <thead className='text-center text-white'>
            <tr className='p-2 font-sans'>
              <th className='rounded-tl-lg bg-lime-600 p-2'>MONTH</th>
              <th className='bg-lime-600 p-2'>PRICE</th>
              <th className='bg-lime-600 p-2'>REVENUE</th>
              <th className='bg-lime-600 p-2'>PROFIT</th>
              <th className='bg-lime-600 p-2'>VOLUME <br></br>(in million)</th>
              {/* <th>V*C</th> */}
              {/* <th>PR</th> */}
              {/* <th>PE</th> */}
              <th className='bg-lime-600 rounded-tr-lg p-2'>VWAP</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {
              value.map(row => (
                <tr key={row.MONTH} className='table-tr'>
                  <td className='p-2'>{row.MONTH}</td>
                  <td>{row.PRICE}</td>
                  <td>{row.REVENUE}</td>
                  <td>{row.PROFIT}</td>
                  <td>{row.VOLUME}</td>
                  {/* <td>{row['V*C']}</td> */}
                  {/* <td>{row.PR}</td> */}
                  {/* <td>{row.PE}</td> */}
                  <td>{row.VWAP}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        {dataLoading ? <LoadingSpinner /> : ""}
        <button className='btn-data' onClick={getStockData}>Get Stock Data</button>
        <hr />
        <table className='table'>
          <thead className='text-center text-white'>
            <tr className='p-2 font-sans'>
              <th className='rounded-tl-lg bg-lime-600 p-2'>MONTH</th>
              <th className='bg-lime-600 p-2'>FORECASTED PRICE</th>
              <th className='bg-lime-600 p-2'>FORECASTED REVENUE</th>
              <th className='bg-lime-600 p-2'>FORECASTED PROFIT</th>
              <th className='bg-lime-600 p-2'>FORECASTED VOLUME <br></br>(in million)</th>
              {/* <th>FORECASTED V*C</th> */}
              <th className='rounded-tr-lg bg-lime-600 p-2'>VWAP</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {
              forecast.map(row => (
                <tr key={row.month} className='table-tr'>
                  <td className='p-2'>{row.month}</td>
                  <td className='font-bold text-[14px] md:text-xl text-green-950'>{row.stock_price}</td>
                  <td>{row.revenue}</td>
                  <td>{row.profit}</td>
                  <td>{row.volume}</td>
                  {/* <td>{Math.abs(row.vc)}</td> */}
                  <td className='px-4'>{row.vwap}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        {forecastLoading ? <LoadingSpinner /> : ""}
      </div>
      <button className='btn-data m-auto mb-10' onClick={getForecastData}>Forecast Data</button>
    </div>
  );
}

export default Tool;