import Dropdown from './Dropdown'

function Faq() {

    const faqs = [
        {
            question: "What is Markestimator?",
            answer: "Markestimator uses historical data, statistical models, and machine learning algorithms to forecast future stock prices and trends. It helps investors analyze potential market movements and make more informed decisions."
        },
        {
            question: "How does the Markestimator work?",
            answer: "The tool analyses past stock prices, trading volumes, and other relevant data to identify patterns and trends. It then applies statistical and machine learning models to generate predictions about future stock performance."
        },
        {
            question: "Is the Markestimator accurate?",
            answer: "While the tool uses advanced algorithms to provide forecasts, no prediction tool can guarantee accuracy. Financial markets are influenced by a wide range of factors, and predictions may not always reflect future market conditions."
        },
        {
            question: "Can I rely on the predictions for investment decisions?",
            answer: "The tool provides insights based on historical data and models, but it should not be used as the sole basis for investment decisions. Always conduct your own research and consult with a financial advisor to make well-informed investment choices."
        },
        {
            question: "How often is the data updated?",
            answer: "Data update frequency is updated at the end of every month and after companies release quarterly earnings."
        },
        {
            question: "Are there any risks associated with using this tool?",
            answer: "Yes, investing in stocks carries inherent risks, including the potential loss of principal. The tool’s predictions should be used as part of a broader investment strategy and not as definitive advice."
        },
        {
            question: "Can I customize the tool to fit my investment strategy?",
            answer: "No, there is no options for tool customization."
        },
        {
            question: "How do I interpret the predictions provided by the tool?",
            answer: "Predictions are typically presented as monthly price targets, trend directions, or probabilities of certain outcomes."
        },
        {
            question: "What should I do if I encounter issues with the tool?",
            answer: "If you experience any issues or have questions about the tool, contact us on using the details here."
        },
        {
            question: "Is there a cost to use Markestimator?",
            answer: "No. Markestimator is free to use"
        }
    ]

    return (
        <div id="faq" className="container-fluid flex bg-[rgba(183,211,240,0.31)] flex-col md:gap-10 min-h-screen lg:items-center">
            <h2 className="font-bold text-3xl md:text-[42px] my-[50px] mx-3 text-green-700">Frequently Asked Questions</h2>
            {
                faqs.map((faq, index) => {
                    return <Dropdown key={index} question={faq.question} answer={faq.answer}/>
                })
            }
            <div></div>
        </div>
    )
}

export default Faq;