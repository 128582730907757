import { FaCircleArrowUp } from 'react-icons/fa6';
import Modal from './Modal'
import { useState } from 'react';

function Footer() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const list = [
        "Markestimator is intended for informational and educational purposes only. It uses historical data, statistical models, and machine learning algorithms to generate forecasts about future stock performance. However, these predictions are based on patterns and data trends that may not account for all market conditions or unforeseen events.",
        "No Guarantee of Accuracy: The predictions and insights produced by this tool are not guaranteed to be accurate or reliable. Financial markets are influenced by numerous factors, including economic indicators, geopolitical events, and investor behaviour, which may not be fully captured by the tool.",
        "Investment Risks: Investing in stocks involves significant risks, including the potential loss of principal. The information provided by this tool should not be interpreted as financial advice, recommendations, or endorsements. Users should perform their own research and analysis and seek advice from qualified financial professionals before making any investment decisions.",
        "Limitation of Liability: The creators and providers of this tool do not accept any liability for any financial losses, damages, or consequences that may arise from the use of this tool or reliance on its predictions. The tool is provided &quot;as is,&quot; and its creators make no representations or warranties regarding its accuracy, completeness, or suitability for any particular purpose.",
        "Changes and Updates: The financial markets are dynamic, and the tools, features, and algorithms may be updated or modified over time. Users are encouraged to stay informed about changes and to use the tool as part of a broader investment strategy.",
        "By using our stock prediction tool, you acknowledge and agree to these terms and conditions and understand that investing always carries risks."
    ]

    return (
        <div className="container-fluid md:text-2xl mb-10 bg-green-100 p-10">
            <section>
                <h2 className="font-bold text-center text-2xl md:text-[42px] my-10 mx-3 text-green-700">Want to get in touch with us?</h2>
            </section>
            <section className="flex flex-col gap-5 items-center mt-5 lg:flex lg:flex-row lg:justify-center lg:items-start lg:gap-60">
                <section className="flex flex-col gap-1">
                    <p className="font-bold">Product</p>
                    <button className="text-red-800 p-1 border-none hover:cursor-pointer -ml-1 md:-ml-8 lg:-ml-4 bg-green-100 text-lg md:text-xl lg:text-2xl" onClick={openModal}>Disclaimer</button>
                    <p>Latest News</p>
                </section>
                <section className="flex flex-col gap-2">
                    <p className="font-bold">Social Media</p>
                    <div className="flex flex-row gap-3">
                        <a href="https://www.linkedin.com/company/markecast" target="_blank" rel="noreferrer"><svg className="h-6 w-6 md:h-10 md:w-10 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />  <rect x="2" y="9" width="4" height="12" />  <circle cx="4" cy="4" r="2" /></svg></a>
                        <svg className="h-6 w-6 md:h-10 md:w-10 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                        <svg className="h-6 w-6 md:h-10 md:w-10 text-black" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg>
                    </div>
                </section>
                <section className="flex flex-col gap-1 items-center lg:items-start">
                    <p className="font-bold text-2xl">Markecast</p>
                    <section className="flex flex-row items-center gap-2">
                        <svg className="h-7 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <p>Chennai, India</p>
                    </section>
                    <p>support@markecast.com</p>
                    <a href="/contact">Contact Us</a>
                </section>
            </section>
            {isModalOpen && (
                <Modal isOpen={openModal} onClose={closeModal} modalTitle="Disclaimer" modalBody={list} />
            )}
            <div className="relative">
                <a href='#home'><FaCircleArrowUp className='absolute right-[10px] bottom-[10px] w-[20px] h-[20px] md:w-[40px] md:h-[40px]' /></a>
                <p className='absolute right-[0px] text-[12px] md:text-[16px]'>Back to top</p>
            </div>
        </div>
    )
}

export default Footer;