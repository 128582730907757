function Team() {
    return (
        <div className="container-fluid flex-col p-5 min-h-screen md:flex md:items-center lg:flex lg:flex-col xl:flex xl:flex-col xl:items-center">
            <h2 className="font-bold text-3xl md:text-[42px] my-[50px] mx-3 text-green-700">Meet our Team</h2>
            <div className="md:text-2xl lg:w-[70vw]">
                <section className="m-4 p-2 lg:flex lg:flex-row lg:items-center lg:gap-52 lg:mb-20">
                    <section>
                        <div className="h-[150px] w-[150px] md:h-[200px] md:w-[200px] bg-blue-200 rounded-full m-auto relative mb-10">
                            <div className="h-[50px] w-[50px] md:w-[80px] md:h-[80px] bg-green-200 absolute top-[100px] md:top-[130px] rounded-full -left-4 z-[-1]"></div>
                            <div className="h-[40px] w-[40px] md:w-[60px] md:h-[60px] bg-green-300 absolute top-[100px] md:top-[130px] rounded-full -right-1 z-[-1]"></div>
                        </div>
                    </section>
                    <section>
                        <h3>Santhosh Kumar</h3>
                        <p className="designation">Founder</p>
                        <p className="leading-6 md:leading-8">Santhosh Kumar is the driving force behind Markecast. With a background in Stock Trading, he brings a wealth of knowledge and a visionary approach to our mission. Santhos is committed to exploring emerging market trends to uncover high-growth potential, leading the team with a focus on innovations and excellence.</p>
                    </section>
                </section>
                <section className="m-4 p-2 lg:flex lg:flex-row-reverse lg:items-center lg:gap-52">
                    <section>
                        <div className="h-[150px] w-[150px] md:h-[200px] md:w-[200px] bg-blue-200 rounded-full m-auto relative mb-10">
                            <div className="h-[50px] w-[50px] md:w-[80px] md:h-[80px] bg-green-200 absolute top-[100px] md:top-[130px] rounded-full -left-4 z-[-1]"></div>
                            <div className="h-[40px] w-[40px] md:w-[60px] md:h-[60px] bg-green-300 absolute top-[100px] md:top-[130px] rounded-full -right-1 z-[-1]"></div>
                        </div>
                    </section>
                    <section>
                        <h3>Mohamed Haneef Yaseen</h3>
                        <p className="designation">Co-Founder & Tech Lead</p>
                        <p className="leading-6 md:leading-8">As our Tech Lead, Mohammad Haneef Yaseen oversees the
                            development and implementation of our technology strategy.
                            With extensive experience in Full Stack Development, He
                            ensures that our solutions are cutting-edge and meet the
                            highest standards. Yaseen is passionate about leveraging
                            modern JavaScript frameworks, driving our technical excellence
                            and innovation.</p>
                    </section>
                </section>
            </div>
        </div>
    )
}

export default Team;