import { useState } from 'react';
import Logo from '../images/logo.png'

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  window.addEventListener('scroll', () => {
    const navbar = document.getElementById('navbar');
    if (window.scrollY > 0) {
      navbar.classList.add('blur');
    } else {
      navbar.classList.remove('blur');
    }
  });

  return (
    <nav id="navbar" className="container-fluid w-[98%] bg-green-500 p-4 fixed top-0 z-50 shadow-md transition duration-300 ease-in-out">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex gap-4">
          <img src={Logo} alt="logo" width={40} height={30} />
          <h2 className="text-white text-2xl font-bold">Markecast</h2>
        </div>
        <div className="hidden md:flex space-x-4">
          <a href="/" className="nav-lg">Home</a>
          <a href="#about" className="nav-lg">About</a>
          <a href="#why-markecast" className="nav-lg">Solutions</a>
          <a href="/contact" className="nav-lg">Contact</a>
        </div>
        <div className="md:hidden mr-8">
          <button onClick={toggleMenu} className="text-gray-300 focus:outline-none border-none bg-green-200 p-2 rounded-md">
            <svg
              className="w-6 h-6 text-blue-950"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>

      <div className={`md:hidden text-xl ${isOpen ? 'h-[180px] transition-[height] duration-500' : 'h-0 transition-[height] duration-500'}`}>
        {
          isOpen && (
            <div>
              <a href="/" className="block px-4 py-2 no-underline text-white hover:bg-transparent" onClick={() => setIsOpen(!isOpen)}>Home</a>
              <a href="/#about" className="block px-4 py-2 no-underline text-white hover:bg-transparent" onClick={() => setIsOpen(!isOpen)}>About</a>
              <a href="/#why-markecast" className="block px-4 py-2 no-underline text-white hover:bg-transparent" onClick={() => setIsOpen(!isOpen)}>Solutions</a>
              <a href="/contact" className="block px-4 py-2 no-underline text-white hover:bg-transparent" onClick={() => setIsOpen(!isOpen)}>Contact</a>
            </div>
          )}
      </div>
    </nav>
  );
};

export default Header;